<template>
  <svg viewBox="0 0 19 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2266 1.61279H9.22656H1.22656" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMinus'
}
</script>
